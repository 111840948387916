import React from 'react';
import { FormattedMessage, IntlShape } from 'gatsby-plugin-react-intl';

import { Paragraph } from '../../common';
import Warning from '../../../icons/warning.inline.svg';
import type * as Schema from '@/webapi/gen/graphql';

type TermsAndConditionsLabelProps = {
  intl: IntlShape;
  model: Schema.BikeModel;
  forArea?: Schema.Area;
};

export function TermsAndConditionsLabel({
  intl,
  model,
  forArea,
}: TermsAndConditionsLabelProps) {
  return (
    <div className="form-check-label-wrapper">
      <Paragraph>
        <FormattedMessage
          id="stripe-checkout.agreement"
          defaultMessage="I agree to the {termsAndConditions}.*"
          values={{
            termsAndConditions: (
              <TermsAndConditionsLink
                intl={intl}
                model={model}
                forArea={forArea}
              />
            ),
          }}
        />
      </Paragraph>
    </div>
  );
}

export function CommercialUseConsentLabel({ intl, model, forArea }) {
  return (
    <div className="form-check-label-wrapper commercial-use-consent-label">
      <Paragraph>
        <FormattedMessage
          id="stripe-checkout.commercial-use"
          defaultMessage="I wont be using the vehicle for commercial purposes as it violates the {termsAndConditions}.*"
          values={{
            termsAndConditions: (
              <TermsAndConditionsLink
                intl={intl}
                model={model}
                forArea={forArea}
                anchor="commercial-use"
              />
            ),
          }}
        />
      </Paragraph>

      <Warning />
    </div>
  );
}

export function LocationConsentLabel({ intl }) {
  const link = (
    <a
      href={intl.formatMessage({ id: 'stripe-checkout.location-consent-link' })}
      target="_blank"
      rel="noreferrer"
    >
      <FormattedMessage
        id="stripe-checkout.privacy-policy-link-text"
        defaultMessage="location information that is essential for service"
      />
    </a>
  );

  return (
    <div className="form-check-label-wrapper">
      <Paragraph>
        <FormattedMessage
          id="stripe-checkout.location-consent"
          defaultMessage="I consent to share {link}.*"
          values={{ link }}
        />
      </Paragraph>
    </div>
  );
}

type TermsAndConditionsLinkProps = {
  intl: IntlShape;
  model: Schema.BikeModel;
  forArea?: Schema.Area;
  anchor?: string;
};

const TermsAndConditionsLink = ({
  intl,
  model,
  forArea,
  anchor,
}: TermsAndConditionsLinkProps) => {
  let href = intl.formatMessage({ id: 'stripe-checkout.terms-link' });

  switch (forArea) {
    case 'VIENNA':
      href = intl.formatMessage({ id: 'stripe-checkout.terms-link-at' });
      break;
    case 'BERLIN':
    case 'HAMBURG':
    case 'MUNICH':
      if (model === 'NIUNQISPORT') {
        href = intl.formatMessage({ id: 'stripe-checkout.terms-link-moped' });
      }
      break;
    case 'PARIS':
      if (model === 'NIUNQISPORT') {
        href = intl.formatMessage({
          id: 'stripe-checkout.terms-link-moped-fr',
        });
      } else {
        href = intl.formatMessage({ id: 'stripe-checkout.terms-link-fr' });
      }
      break;
  }

  const hash = anchor ? `#${anchor}` : '';

  return (
    <>
      <a target="_blank" rel="noreferrer" href={`${href}${hash}`}>
        <FormattedMessage
          id="stripe-checkout.terms-and-conditions"
          defaultMessage="Terms and Conditions"
        />
      </a>
    </>
  );
};

import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl, navigate } from 'gatsby-plugin-react-intl';
import {
  CardElement,
  IbanElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripeConstructorOptions,
  StripeError,
} from '@stripe/stripe-js';
import { useGoal } from 'gatsby-plugin-fathom';
import { Button, Form } from 'react-bootstrap';
import { CHECKOUT_PAY } from '@/webapi/WebApi';
import client from '@/webapi/client';
import { CheckoutPayResponse } from '@/webapi/gen/graphql';
import { track } from '../../tracking/segment';

import './stripecheckout.scss';
import { MarketingPermission } from '../permissions/marketing';
import { FormSpinner } from '../entry/form';
import {
  Paragraph,
  Section,
  SectionHeadline,
  SectionSubheadline,
} from '../../common';
import { BIKE_MODEL, CITY_COUNTRY_MAP } from '@/config/constants';
import { useCheckoutState } from '@/hooks/useCheckoutState';
import stripePromise from '@/helpers/loadStripe';
import { modelName } from '@/helpers/i18n';
import { checkoutPrice } from '@/helpers/pricing';
import PaymentInput from './payment-input';
import {
  CommercialUseConsentLabel,
  LocationConsentLabel,
  TermsAndConditionsLabel,
} from './terms-and-conditions';
import { ErrorMessage } from '@/components/common/form/feedback';

const CheckoutButton = ({
  checkoutId,
  billingName,
  billingEmail,
  billingLine1,
  billingLine2,
  billingCity,
  billingCountry,
  billingZipcode,
  b2bCompany,
  paymentScheme,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [paymentMethod, setPaymentMethod] = useState('card');

  const [agreementsError, setAgreementsError] = useState({
    location: false,
    terms: false,
    commercial: false,
  });
  const [terms, setTerms] = useState(false);
  const [locationConsent, setLocationConsent] = useState(false);
  const [commercialUseConsent, setCommercialUseConsent] = useState(false);

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );
  const [browserPaymentLoaded, setBrowserPaymentLoaded] = useState(false);
  const [browserPaymentMethods, setBrowserPaymentMethods] =
    useState<CanMakePaymentResult | null>(null);
  const [
    marketingCommunicationPermission,
    setMarketingCommunicationPermission,
  ] = useState(false);

  const intl = useIntl();
  const checkoutGoal = useGoal('PVLQGPMI');
  const {
    checkoutState,
    config: { paymentMethods },
  } = useCheckoutState();
  const showNote = b2bCompany ? 'b2b' : paymentMethod;

  const paymentMethodStateRef = useRef(paymentMethod);
  const marketingCommunicationPermissionStateRef = useRef(
    marketingCommunicationPermission
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: CITY_COUNTRY_MAP[checkoutState.danceArea],
      currency: 'eur',
      total: {
        label: 'Dance',
        amount: checkoutPrice(checkoutState, paymentScheme),
      },
      requestPayerEmail: true,
    });

    pr.canMakePayment()
      .then((result: CanMakePaymentResult) => {
        if (result) {
          setPaymentRequest(pr);
          setBrowserPaymentMethods(result);

          if (result.applePay) {
            setPaymentMethodRef('applePay');
          }

          if (result.googlePay) {
            setPaymentMethodRef('googlePay');
          }
        }
      })
      .finally(() => {
        setBrowserPaymentLoaded(true);
      });
  }, [stripe, paymentScheme]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('stripe_error') || !browserPaymentLoaded) {
      return;
    }

    setError({
      message: intl.formatMessage({
        id: 'stripe-checkout.error-title',
        defaultMessage: 'Your payment could not be taken. Please try again.',
      }),
    });

    const paymentMethodParam = urlParams.get('payment_method');
    if (paymentMethodParam) {
      setPaymentMethodRef(paymentMethodParam);
    }
  }, [window.location.search, browserPaymentLoaded]);

  const setPaymentMethodRef = (data: string): void => {
    paymentMethodStateRef.current = data;
    setPaymentMethod(data);
  };

  const setMarketingCommunicationPermissionRef = (data: boolean): void => {
    marketingCommunicationPermissionStateRef.current = data;
    setMarketingCommunicationPermission(data);
  };

  const confirmPayment = async (
    paymentMethod: any,
    clientSecret: string,
    paymentMethodId?: string
  ): Promise<StripeError | undefined> => {
    let stripeError: StripeError | undefined = undefined;

    if (!stripe) {
      return;
    }

    switch (paymentMethod) {
      case 'PAYPAL':
        {
          const paymentSuccessUrl = new URL(window.location.origin);
          paymentSuccessUrl.pathname = '/c/payment-redirect';
          paymentSuccessUrl.searchParams.append('cid', checkoutId);

          const stripeResult = await stripe.confirmPayPalPayment(clientSecret, {
            return_url: paymentSuccessUrl.toString(),
            mandate_data: {
              customer_acceptance: {
                type: 'online',
                online: {
                  infer_from_client: true,
                },
              },
            },
          });

          stripeError = stripeResult.error;
        }
        break;
      case 'GOOGLEPAY':
      case 'APPLEPAY':
        {
          const stripeResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId,
          });

          stripeError = stripeResult.error;
        }
        break;

      case 'CARD':
        {
          const stripeResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements?.getElement(CardElement)!,
              billing_details: {
                name: billingName,
                email: billingEmail,
                address: {
                  line1: billingLine1,
                  line2: billingLine2,
                  city: billingCity,
                  postal_code: billingZipcode,
                  country: billingCountry,
                },
              },
            },
          });

          stripeError = stripeResult.error;
        }
        break;

      case 'SEPA_DEBIT':
        {
          const stripeResult = await stripe.confirmSepaDebitPayment(
            clientSecret,
            {
              payment_method: {
                sepa_debit: elements?.getElement(IbanElement)!,
                billing_details: {
                  name: billingName,
                  email: billingEmail,
                  address: {
                    line1: billingLine1,
                    line2: billingLine2,
                    city: billingCity,
                    postal_code: billingZipcode,
                    country: billingCountry,
                  },
                },
              },
            }
          );

          stripeError = stripeResult.error;
        }
        break;

      case 'SOFORT':
        {
          const paymentSuccessUrl = new URL(window.location.origin);
          paymentSuccessUrl.pathname = '/c/payment-redirect';
          paymentSuccessUrl.searchParams.append('cid', checkoutId);

          const stripeResult = await stripe.confirmSofortPayment(clientSecret, {
            payment_method: {
              sofort: {
                country: CITY_COUNTRY_MAP[checkoutState.danceArea],
              },
              billing_details: {
                name: billingName,
                email: billingEmail,
                address: {
                  line1: billingLine1,
                  line2: billingLine2,
                  city: billingCity,
                  postal_code: billingZipcode,
                  country: billingCountry,
                },
              },
            },
            return_url: paymentSuccessUrl.toString(),
          });

          stripeError = stripeResult.error;
        }
        break;
      default:
        break;
    }

    return stripeError;
  };

  const submitPayment = async (): Promise<{
    paymentClientSecret: string;
    paymentMethod: string;
  }> => {
    const dancePayResponse = {
      paymentClientSecret: '',
      paymentMethod: '',
    };

    const currentPaymentMethod = paymentMethodStateRef.current;
    const currentMarketingPermissions =
      marketingCommunicationPermissionStateRef.current;

    try {
      track('Checkout Step Completed', {
        checkout_id: checkoutId,
        step_name: 'payment-details',
        payment_method: currentPaymentMethod,
      });
      const { data } = await client.mutate<{
        checkoutPay2: CheckoutPayResponse;
      }>({
        mutation: CHECKOUT_PAY,
        variables: {
          input: {
            id: checkoutId,
            paymentMethod: currentPaymentMethod.toUpperCase(),
            marketingCommunicationPermission: currentMarketingPermissions,
            paymentScheme: checkoutState.priceDetail.yearlyPrepayment
              ? paymentScheme
              : 'MONTHLY',
          },
        },
      });

      if (!data?.checkoutPay2) {
        throw new Error('Bad response from checkoutPay2');
      }

      if (data.checkoutPay2.__typename === 'CheckoutPayError') {
        throw new Error(data.checkoutPay2.error);
      } else if (data.checkoutPay2.__typename === 'CheckoutPaySuccess') {
        dancePayResponse.paymentClientSecret =
          data.checkoutPay2.stripePaymentClientSecret;
        dancePayResponse.paymentMethod = data.checkoutPay2.paymentMethod;
      }
    } catch (error) {
      setLoading(false);

      switch (error.message) {
        case 'EMAIL_NOT_ALLOWED':
          setError({
            message: intl.formatMessage({
              id: 'stripe-checkout.error-email-not-allowed',
              defaultMessage:
                'Your payment could not be taken, because the email address is invalid. Please use another one.',
            }),
          });
          break;
        case 'ALREADY_PAID':
          setError({
            message: intl.formatMessage({
              id: 'stripe-checkout.error-already-paid',
              defaultMessage:
                'Your payment could not be processed, because you already paid.',
            }),
          });
          break;
        case 'INVITE_CODE_INVALID':
          setError({
            message: intl.formatMessage({
              id: 'stripe-checkout.error-invite-code-invalid',
              defaultMessage:
                'Your payment could not be taken, because the invite code is invalid.',
            }),
          });
          break;
        case 'REFERRAL_CODE_INVALID':
          setError({
            message: intl.formatMessage({
              id: 'stripe-checkout.error-referral-code-invalid',
              defaultMessage:
                'Your payment could not be taken, because the referral code is invalid.',
            }),
          });
          break;
        case 'INVALID_CHECKOUT':
        case 'UNKNOWN':
        default:
          window.Sentry.captureException(error);
          setError({
            message: intl.formatMessage({
              id: 'stripe-checkout.error-title',
              defaultMessage:
                'Your payment could not be taken. Please try again.',
            }),
          });
      }
    }

    return dancePayResponse;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setAgreementsError({ location: false, commercial: false, terms: false });

    if (!(b2bCompany || (terms && commercialUseConsent)) || !locationConsent) {
      const termsErrors: string[] = [];

      if (!locationConsent) {
        setAgreementsError((state) => ({ ...state, location: true }));
        termsErrors.push('location');
      }

      if (!terms) {
        setAgreementsError((state) => ({ ...state, terms: true }));
        termsErrors.push('terms');
      }

      if (!commercialUseConsent) {
        setAgreementsError((state) => ({ ...state, commercial: true }));
        termsErrors.push('commercial');
      }

      track('Checkout Step Error', {
        checkout_id: checkoutId,
        step_name: 'payment-details',
        substep_name: 'submit-payment',
        payment_method: paymentMethod,
        error: 'Missing consent to terms',
        error_message: termsErrors.join(', '),
      });

      return;
    }

    setError(null);

    if (loading) {
      return;
    }

    const isWalletPayment =
      paymentMethod === 'googlePay' || paymentMethod === 'applePay';

    setLoading(true);

    let stripeError: StripeError | undefined = undefined;
    let stripeWalletErrorTask: Promise<StripeError | undefined> | undefined =
      undefined;

    if (isWalletPayment && paymentRequest) {
      // Calling show now because iOS enforces that it gets called directly from the user-interaction event handler
      // Calling it after a `await` would be refused
      paymentRequest.show();

      stripeWalletErrorTask = new Promise<StripeError | undefined>((res) => {
        paymentRequest.on(
          'paymentmethod',
          async (event: PaymentRequestPaymentMethodEvent) => {
            const stripeError = await confirmPayment(
              paymentResponse.paymentMethod,
              paymentResponse.paymentClientSecret,
              event.paymentMethod.id
            );

            if (stripeError) {
              event.complete('fail');
            } else {
              event.complete('success');
            }

            res(stripeError);
          }
        );
      }).finally(() => paymentRequest.off('paymentmethod'));
    }

    const paymentResponse = await submitPayment();

    if (isWalletPayment) {
      if (!stripeWalletErrorTask) {
        return;
      }

      stripeError = await stripeWalletErrorTask;
    } else {
      stripeError = await confirmPayment(
        paymentResponse.paymentMethod,
        paymentResponse.paymentClientSecret
      );
    }

    if (!stripeError) {
      track('Checkout Step Completed', {
        checkout_id: checkoutId,
        step_name: 'payment-details',
        substep_name: 'submit-payment',
        payment_method: paymentResponse.paymentMethod,
      });

      checkoutGoal();
      setError(null);
      navigate('/m/start/');
      return;
    }

    setLoading(false);
    setError({ message: `${stripeError.message}` });
    track('Checkout Step Error', {
      checkout_id: checkoutId,
      step_name: 'payment-details',
      substep_name: 'submit-payment',
      payment_method: paymentResponse.paymentMethod,
      error: 'StripeCheckout failed',
      error_message: stripeError.message,
    });
  };

  const handleChangePaymentMethod = (method: string): void => {
    setPaymentMethodRef(method);
    track('Checkout Step Completed', {
      checkout_id: checkoutId,
      step_name: 'payment-details',
      substep_name: 'payment-method-selected',
      payment_method: method,
    });
  };

  const selectedBikeModelTitle = modelName(intl, checkoutState.model!);

  const getPaymentMethodsAvailable = (): { [key: string]: boolean } => {
    return paymentMethods.reduce((acc, curr) => {
      if (curr === 'APPLEPAY') {
        const key = `${curr.toLowerCase()}Enabled`;
        acc[key] = !!browserPaymentMethods?.applePay;
        return acc;
      }

      if (curr === 'GOOGLEPAY') {
        const key = `${curr.toLowerCase()}Enabled`;
        acc[key] = !!browserPaymentMethods?.googlePay;
        return acc;
      }

      const key = `${curr.toLowerCase()}Enabled`;
      acc[key] = true;
      return acc;
    }, {});
  };

  return (
    <Form onSubmit={handleSubmit} className="stripe-checkout">
      <Section>
        <SectionHeadline>
          <FormattedMessage
            id="stripe-checkout.title"
            defaultMessage="Payment details"
          />
        </SectionHeadline>
        <SectionSubheadline>
          {showNote === 'b2b' && (
            <FormattedMessage
              id="stripe-checkout.b2b"
              defaultMessage="We need your payment information to create your subscription. You will only be charged if you leave your company, if you decide to add any accessories, or for excesses in case of theft."
            />
          )}
          {showNote === paymentMethod && (
            <FormattedMessage
              id="stripe-checkout.trial-summary-sepa-debit"
              defaultMessage="We’ll take the first month’s payment at checkout but your subscription only starts when your {model} is delivered. Subsequent payments are made monthly from the date of delivery."
              values={{ model: selectedBikeModelTitle }}
            />
          )}
        </SectionSubheadline>
        <Form.Group>
          {browserPaymentLoaded && (
            <PaymentInput
              error={error ?? undefined}
              paymentMethod={paymentMethod}
              setError={(e) =>
                setError(e?.message ? { message: e.message } : null)
              }
              availablePaymenthMethods={getPaymentMethodsAvailable()}
              onChange={handleChangePaymentMethod}
            />
          )}
          {(paymentMethod === 'sepa_debit' || paymentMethod === 'sofort') && (
            <p className="mandate-acceptance" style={{ fontSize: '0.7em' }}>
              <FormattedMessage id="stripe-checkout.sdd-mandate" />
            </p>
          )}
        </Form.Group>

        <br />

        <Form.Group>
          {showNote !== 'b2b' && (
            <Form.Check
              custom
              type="checkbox"
              className="form-check-checkbox"
              id="checkoutTerms"
              data-test-id="terms-and-conditions"
              checked={terms}
              isInvalid={!terms && agreementsError.terms}
              label={
                <TermsAndConditionsLabel
                  intl={intl}
                  model={checkoutState.model || 'DANCEONEDIAMOND'}
                  forArea={checkoutState.danceArea}
                />
              }
              onChange={() => {
                setAgreementsError((state) => ({ ...state, terms: false }));
                setTerms(!terms);
              }}
            />
          )}

          <Form.Check
            custom
            type="checkbox"
            className="form-check-checkbox"
            id="checkoutLocationConsent"
            checked={locationConsent}
            data-test-id="location-consent"
            isInvalid={!locationConsent && agreementsError.location}
            label={<LocationConsentLabel intl={intl} />}
            onChange={() => {
              setAgreementsError((state) => ({ ...state, location: false }));
              setLocationConsent(!locationConsent);
            }}
          />

          <MarketingPermission
            onChange={() =>
              setMarketingCommunicationPermissionRef(
                !marketingCommunicationPermission
              )
            }
          />

          {showNote !== 'b2b' && (
            <div className="commercial-use-consent-field">
              <Form.Check
                custom
                type="checkbox"
                id="checkoutCommercialUseConsent"
                className="form-check-checkbox commercial-use-consent"
                checked={commercialUseConsent}
                isInvalid={!commercialUseConsent && agreementsError.commercial}
                label={
                  <CommercialUseConsentLabel
                    intl={intl}
                    model={checkoutState.model || BIKE_MODEL.DANCEONEDIAMOND}
                    forArea={checkoutState.danceArea}
                  />
                }
                data-test-id="commercial-use-consent"
                onChange={() => {
                  setAgreementsError((state) => ({
                    ...state,
                    commercial: false,
                  }));
                  setCommercialUseConsent(!commercialUseConsent);
                }}
              />
            </div>
          )}
        </Form.Group>

        {Object.values(agreementsError).includes(true) && (
          <Form.Group>
            <ErrorMessage
              msg={intl.formatMessage({
                id: 'stripe-checkout.agreements-required',
                defaultMessage: 'Please check the required boxes.',
              })}
            />
          </Form.Group>
        )}

        <Paragraph>
          <FormattedMessage
            id="stripe-checkout.asterisk-disclaimer"
            defaultMessage="* = Required."
          />
        </Paragraph>
      </Section>
      <div className="continue-button">
        <Button variant="primary" type="submit" data-test-id="submit-checkout">
          {loading ? (
            <FormSpinner />
          ) : (
            <FormattedMessage
              id="stripe-checkout.confirm-subscription-button"
              defaultMessage="Subscribe now"
            />
          )}
        </Button>
      </div>
    </Form>
  );
};

const InjectedCheckoutForm = ({
  checkoutId,
  billingName,
  billingEmail,
  billingLine1,
  billingLine2,
  billingCity,
  billingZipcode,
  billingCountry,
  b2bCompany,
  stripeKey,
  paymentScheme,
}) => {
  const { locale } = useIntl();

  return (
    <Elements
      stripe={stripePromise(
        stripeKey,
        locale as StripeConstructorOptions['locale']
      )}
    >
      <CheckoutButton
        checkoutId={checkoutId}
        billingName={billingName}
        billingEmail={billingEmail}
        billingLine1={billingLine1}
        billingLine2={billingLine2}
        billingCity={billingCity}
        billingZipcode={billingZipcode}
        billingCountry={billingCountry}
        b2bCompany={b2bCompany}
        paymentScheme={paymentScheme}
      />
    </Elements>
  );
};

export default InjectedCheckoutForm;
